import { makeStyles } from "@material-ui/core";
import BeforeStartBackground from '../../assets/images/before_start_background.png';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#EBE2CF",
    backgroundImage: `url(${BeforeStartBackground})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    position: "relative",
    display: "flex",
  },
  logo: {
    mixBlendMode: "multiply",
    position: "absolute",
    top: 10,
    left: 20,
    height: "25px",
    width: "125px",
    "@media (min-width: 600px)": {
      height: "40px",
      width: "200px"
    }
  },
  backBtn: {
    position: "absolute",
    top: 84,
    left: 40,
    cursor: "pointer"
  },
  leftRockImg: {
    position: "relative",
    left: 0,
    top: 141
  },
  rightRockImg: {},
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  title: {
    fontSize: "48px",
    lineHeight: 1.2,
    fontWeight: 600
  },
  subTitle: {
    fontSize: "18px",
    lineHeight: 1.5,
    fontWeight: 500,
    whiteSpace: "pre-line",
    textAlign: "center",
    marginTop: "16px",
    color: "#475569"
  },
  circle1: {
    borderWidth: 1,
    border: "1px solid #3A84C9cc",
    padding: "26px",
    borderRadius: "500px",
    position: "relative",
    '& svg': {
      '& rect': {
        fill: 'white'
      }
    }
  },
  circle2: {
    border: "1px solid #3a84c999"
  },
  circle3: {
    border: "1px solid #3A84C966"
  },
  circle4: {
    border: "1px solid #3A84C91a",
    marginTop: "50px"
  }
}));
