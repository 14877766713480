import React, { useEffect, useState, lazy, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { routeMap } from '../helpers/routes';
import { LAYOUTS } from '../utils/enums';
import { Box, Backdrop, CircularProgress } from '@material-ui/core';
import { matchRoute } from '../helpers/routes';

// Lazy load layouts
const PlatformLayout = lazy(() => import('../layouts/PlatformLayout'));
const CarouselLayout = lazy(() => import('../layouts/CarouselLayout'));
const OnboardingLayout = lazy(() => import('../layouts/OnboardingLayout'));

const LayoutsProvider = ({ children, location }) => {
  const [prevUrl, setPrevUrl] = useState(null);
  const [layout, setLayout] = useState(null);
  const [renderChildren, setRenderChildren] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const currentPath = location.pathname;
    const prevRoute = Object.values(routeMap).find((route) => matchRoute(prevUrl, route.path));
    const matchedRoute = Object.values(routeMap).find((route) => matchRoute(currentPath, route.path));

    if (
      prevRoute?.layout !== matchedRoute?.layout ||
      prevRoute?.private !== matchedRoute?.private
    ) {
      // Set layout and show loading indicator
      setIsLoading(true);
      setLayout(matchedRoute?.layout);

      // Simulate waiting for layout render
      const timer = setTimeout(() => {
        setRenderChildren(children);
        setIsLoading(false);
        setPrevUrl(currentPath);
      }, prevRoute?.layout !== matchedRoute?.layout ? 300 : 0); // Use a short delay to let the layout render first

      return () => clearTimeout(timer);
    }
  }, [location.pathname, children]);
  
  return (
    <Suspense
      fallback={
        <Backdrop open={true} style={{ zIndex: 9999 }}>
          <CircularProgress />
        </Backdrop>
      }
    >
      {isLoading ? (
        <Backdrop open={true} style={{ zIndex: 9999 }}>
          <CircularProgress />
        </Backdrop>
      ) : layout === LAYOUTS.CAROUSEL_LAYOUT ? (
        <CarouselLayout>
          {renderChildren}
        </CarouselLayout>
      ) : layout === LAYOUTS.ONBOARDING_LAYOUT ? (
        <OnboardingLayout>
          {renderChildren}
        </OnboardingLayout>
      ) : layout === LAYOUTS.PLATFORM_LAYOUT ? (
        <PlatformLayout>
          {renderChildren}
        </PlatformLayout>
      ) : (
        <Box style={{ zIndex: 1 }}>
          {renderChildren}
        </Box>
      )}
    </Suspense>
  );
};

export const LayoutProvider = withRouter(LayoutsProvider);
