import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  dialog: {},

  title: {
    position: "relative",
    padding: "44px 44px 0"
  },

  content: {
    marginTop: 28,
    padding: "0 44px"
  },

  icon: {
    fontSize: 24,
    color: "#3A84C9"
  },

  closeButton: {
    position: "absolute",
    right: 20,
    top: 20
  },

  topContent: {},

  description: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "22.4px"
  },

  list: {
    marginTop: 20
  },

  listTitle: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "19.2px"
  },

  listContent: {
    margin: 0,
    marginTop: 8,
    paddingLeft: 12
  },

  listItem: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "21px",
    color: "#0F172A"
  },

  action: {
    marginTop: 40,
    padding: "0 44px 44px"
  },

  button: {
    padding: "10px 16px",
    borderRadius: 8,
    backgroundColor: "#000000",
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "19.2px",
    width: "100%",
    cursor: "pointer"
  }
}));
