import React from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import UploadCompanyDocuments from '../../../UploadCompanyDocuments';

import { useStyles } from "./styles";

const UploadDocumentsModal = ({
  open,
  onlyFiles,
  onClose,
  onAfterClose
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: { borderRadius: 20, maxWidth: 848, width: "100%" }
      }}
    >
      <DialogContent className={classes.content}>
        <IconButton 
          className={classes.closeIcon} 
          onClick={onClose}
        >
          <Close />
        </IconButton>

        <UploadCompanyDocuments
          view={'modal'}
          onlyFiles={onlyFiles}
          onClose={onClose}
          onAfterClose={onAfterClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default UploadDocumentsModal;
