import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '1920px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    padding: '20px 65px 65px 20px',
    margin: '0 auto'
  },
  title: {
    width: '100%',
    fontSize: '48px',
    fontWeight: 600,
    lineHeight: '58px',
    textAlign: 'center'
  },
  tabs: {
    width: '73%',
    maxWidth: '807px',
    maxHeight: '55px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    borderRadius: '80px',
    border: '1px solid #CBD5E1',
  },
  tab: {
    maxWidth: 'fit-content !important',
    minWidth: 'fit-content !important',
    height: '51px',
    padding: '16px 32px',
    borderRadius: '80px',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19px',
    boxSizing: 'border-box'
  },
  groupsWrapper: {
    width: '100%',
    height: '300px',
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
  },
  groupsLoader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  group: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '15px',
    padding: '35px 40px',
    background: '#F1F5F9',
    borderRadius: '28px'
  },
  groupTitle: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '12px'
  },
  cardsWrapper: {
    display: 'flex',
    gap: '20px',
    flexWrap: 'wrap'
  },
  card: {
    height: '250px',
    minHeight: '250px',
    maxHeight: '250px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '1px solid #57BE99',
    borderRadius: '20px',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '325px',
    padding: '25px 20px 44px 25px',
    overflow: 'hidden',
    background: 'white',
    cursor: 'pointer',
    transition: 'all 0.3s',
    position: 'relative',
    '&:hover': {
      background: '#57BE99',
      '& p': {
        color: 'white !important',
      },
      '& $cardHeader svg': {
        color: 'white !important',
        '& path': {
          fill: 'white !important',
        }
      },
      '& $cardContent p:first-of-type': {
        color: '#FFFFFF !important',
      },
      '& $cardFooterItem p:last-of-type': {
        color: '#FFFFFF !important',
      }
    },
    '&:hover $cardBackgroundInner svg path': {
      fill: 'white'
    },
    '&:hover $cardBackgroundOutter svg path': {
      fill: 'white',
    }
  },
  cardBackground: {
    width: 'fit-content',
    height: 'fit-content',
    position: 'absolute',
    right: '0px',
    bottom: '0px'
  },
  cardBackgroundOutter: {
    position: 'absolute',
    bottom: '-5px',
    right: '0px',
    zIndex: 1
  },
  cardBackgroundInner: {
    position: 'absolute',
    bottom: '-5px',
    right: '0px',
    zIndex: 2
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px'
  },
  cardFooter: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '20px'
  },
  cardFooterItem: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px'
  }
}));
