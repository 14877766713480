import { WithStyles } from "@material-ui/core";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/main/Messages/MessageEnum";
import { Message } from "../../../framework/main/Message";
import { runEngine } from "../../../framework/main/RunEngine";
import { letsValues } from "./LetsGetStarted.web";
import { parseErrors } from '../../utilities/main/Toast'
import StorageProvider from "../../../framework/main/StorageProvider";

export interface Props extends WithStyles<any> {
  navigation?: any;
  id?: string;
  email?: string;
}

export const configJSON = require("./config");

export interface S {
  values: any;
  isAgreed: boolean;
  email: string | null;
  fullname: string | null;
  role: string | null;
  name: any;
  errorsE: any;
  message: any;
  isLoad: boolean;
  isSubmitting: boolean;
  roleList: any[];
  enablePasswordField: boolean;
  errors: any;
}

export interface SS {
  id: any;
}

export default class LetsGetStartedController extends BlockComponent<Props, S, SS> {
  referralCode: string | null = ""
  apiStartCallId: string = "";
  fetchRoleListId: string = "";
  token: string;

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      values: "",
      role: "",
      isSubmitting: false,
      isAgreed: false,
      email: "",
      fullname: "",
      errorsE: "",
      message: "",
      isLoad: false,
      name: "",
      roleList: [
        { id: "", name: "Select response" }
      ],
      errors: "",
      enablePasswordField: true,
    };
  }

  async componentDidMount() {
    super.componentDidMount();

    const token = await StorageProvider.getItem("user-token");
    this.token = token;

    if (this.token) {
      this.props.navigation.navigate("LandingPage");
    }

    const email = window.localStorage.getItem("sign-up-email")
    this.setState({ email })
    this.fetchRoleList()
    this.getReferralCode()
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.apiStartCallId) {
        if (responseJson?.data?.attributes && responseJson?.meta?.token) {
          window.localStorage.setItem("sign-up-email", responseJson.data.attributes.email);
          // window.localStorage.setItem(STEPS_TYPE.SIGN_UP, SIGN_UP_STEPS.DESCRIBE_BUSINESS_1);
          await StorageProvider.setItem("user-token", responseJson.meta.token);
          await StorageProvider.setItem("user-refresh-token", responseJson.meta.refresh_token);

          const message: Message = new Message(getName(MessageEnum.NavigationMessage))
          message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'CompanyDetail'
          );
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
          this.send(message);
        } else {
          const errorsE = parseErrors(responseJson);
          this.setState({ errorsE: errorsE ? errorsE : responseJson[0].account });
        }
      }

      if (apiRequestCallId === this.fetchRoleListId) {
        this.handleRoleListAPI(responseJson);
      }
    }
  }

  fetchRoleList(): boolean {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchRoleListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.roleListEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  onBack = async () => {
    await this.saveReferralCode()
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      // 'ChooseOption'
      'SignUp'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handleRoleListAPI = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson?.data?.roles) {
        this.setState({ roleList: [...this.state.roleList, ...responseJson?.data?.roles] })
      }
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);
      this.setState({ errors })
    }
  }

  onClose = () => {
    this.setState({ errorsE: "" });
  };

  handleShowPassword() {
    this.setState({ enablePasswordField: !this.state.enablePasswordField });
  }

  doSignup(values: letsValues) {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const httpsBody = {
      "data": {
        "type": "email_account",
        "attribute": {
          "email": values.email,
          "account_type": "company",
          "role_id": values.role,
          "full_name": values.fullname,
          "password": values.password
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiStartCallId = requestMessage.messageId;

    let endPointUrl = configJSON.googleLoginAPIEndPoint

    if (this.referralCode) {
      endPointUrl = `${configJSON.googleLoginAPIEndPoint}?referral_code=${this.referralCode}`
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpsBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getReferralCode = async () => {
    const url = new URL(window.location.href)
    const queryParams = url.searchParams;
    const code = queryParams.get("referral_code") ?? await StorageProvider.getItem("referral_code")
    if (code) {
      this.referralCode = code
    }
    let newUrl = `${url.origin}${url.pathname}`
    if (this.referralCode) {
      newUrl = `${newUrl}?referral_code=${this.referralCode}`
    }
    window.history.replaceState({ path: newUrl }, "", newUrl);
    await StorageProvider.removeItem("referral_code");
  }

  saveReferralCode = async () => {
    if (!this.referralCode) { return }
    await StorageProvider.setItem("referral_code", this.referralCode);
  }
}
