import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: -120,
    left: -206
  },

  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 2
  },

  content: {
    backgroundColor: "transparent",
    boxShadow: "none",
    width: 252
  },

  descriptionContainer: {
    position: "relative"
  },

  description: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "19.2px",
    color: "#FFFFFF"
  },

  button: {
    padding: "10px 16px",
    borderRadius: 8,
    backgroundColor: "transparent",
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "19.2px",
    marginTop: 12,
    border: "1px solid #CBD5E1",
    cursor: "pointer"
  },

  arrow: {
    position: "absolute",
    right: 16
  },

  arrowInner: {
    position: "relative"
  },

  highlight: {
    position: "relative",
    top: 20,
    left: 142
  },

  hightLightInner1: {
    position: "absolute",
    width: 174,
    height: 174,
    border: "1px solid rgba(255, 255, 255, 0.1)",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  hightLightInner2: {
    position: "absolute",
    width: 124,
    height: 124,
    border: "1px solid rgba(255, 255, 255, 0.3)",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  hightLightInner3: {
    position: "absolute",
    width: 86,
    height: 86,
    border: "1px solid rgba(255, 255, 255, 0.6)",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  hightLightInner4: {
    position: "relative",
    width: 52,
    height: 52,
    borderRadius: "50%",
    background:
      "linear-gradient(144.88deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 3
  },

  highlightIcon: {
    fontSize: 28,
    color: "#3A84C9",
    cursor: "pointer",
    zIndex: 3
    // border: '6px solid #FFFFFF',
    // borderRadius: '50%',
    // '& path': {
    //   width: '16px',
    //   Height: '16px',
    // }
  },

  clearInner: {
    border: "none",
    background: "none"
  },

  pointerEventsNone: {
    pointerEvents: "none",
    cursor: "initial"
  },

  displayNone: {
    display: "none"
  },

  "@media (max-height: 720px)": {
    container: {
      top: 120
    },
    arrow: {
      top: -100,
      transform: "scale(1, -1)"
    }
  }
}));
