export const PERMISSIONS = {
  EDITOR: "Editor",
  REVIEWER: "Reviewer",
  ADMIN: "Admin",
  // SUPER_ADMIN: "Super Admin"
};

export const LAYOUTS = {
  CAROUSEL_LAYOUT: "CarouselLayout",
  ONBOARDING_LAYOUT: "OnboardingLayout",
  PLATFORM_LAYOUT: "PlatformLayout"
};
