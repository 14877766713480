import React, { useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import {
  ArrowBackIos,
  DeleteOutline,
  InsertDriveFile,
  SaveAlt,
} from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { convertToBytes, getFileExtension } from '../../../../helpers/file';

import { useStyles } from './styles';

interface Props {
  formats?: "all" | ("txt" | "pdf" | "doc" | "docx" | "xls" | "xlsx")[];
  error?: string;
  size?: {
    number: string | number,
    unit: 'Bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB' | 'ZB' | 'YB'
  };
  maxFiles?: number;
  multiple?: boolean;
  onChange: (successful: FileList, erroneous: { file: File, message: string }[]) => void;
}

const UploadZone: React.FC<Props> = (props) => {
  const {
    formats = 'all',
    error,
    size,
    maxFiles,
    multiple = false,
    onChange,
  } = props;

  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const dropZone = e.currentTarget;
    
    if (e.type === "dragenter" || e.type === "mouseover" || e.type === "mouseenter") {
      dropZone.style.border = "2px dashed #3A84C9";
      dropZone.style.background = "#3A84C90D";
      dropZone.style.cursor = "pointer";
    }
  
    if (e.type === "dragleave" || e.type === "mouseleave") {
      dropZone.style.border = "1px solid #CBD5E1";
      dropZone.style.background = "transparent";
      dropZone.style.cursor = "pointer";
    }
  }

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const dropZone = e.currentTarget;

    dropZone.style.border = "1px solid #CBD5E1";
    dropZone.style.background = "transparent";
    dropZone.style.cursor = "pointer";

    const files = ('dataTransfer' in e && e.dataTransfer.files.length)
      ? e.dataTransfer.files
      : e.target.files.length
        ? e.target.files
        : null

    if (files?.length) {
      Array.from(files).forEach((file) => {
        (file as any).id = uuidv4();
      });

      const erroneous = Array.from(files).map((item: any) => {
        if (
          formats !== 'all' &&
          !formats.map(item => item.toLowerCase()).includes(
            getFileExtension(item.name).toLowerCase()
          )
        ) {
          return {
            file: item,
            message: `${getFileExtension(item.name).toUpperCase()} format is not supported. Please, try different one.`
          }
        }

        if (item.size > convertToBytes(size.number, size.unit)) {
          return {
            file: item,
            message: `${item.name} is too large. Allowed maximum size is ${size.number}${size.unit}!`
          }
        }
      }).filter(item => item);

      const successful = Array.from(files).filter((item: any) => !erroneous.some((qwe: any) => qwe.file.id === item.id));

      const dataTransfer = new DataTransfer();
      successful.forEach((file: File) => dataTransfer.items.add(file));
      
      onChange(dataTransfer.files, erroneous);
    }

    inputRef.current.value = null;
  }

  return (
    <Box className={classes.importFileSectionWrapper}>
      <label
        className={classes.importFileInput}
        htmlFor="file-input"
      >
        <Box
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onMouseEnter={handleDrag}
          onMouseOver={handleDrag}
          onMouseLeave={handleDrag}
          onDrop={handleDrop}
          className={classes.importDropfileWrapper}
        >
          <SaveAlt className={classes.importUploadIcon} />
          <Typography className={classes.importUploadTitle}>
            Drag and Drop file here or
            <Typography
              component="span"
              className={classes.importChooseFile}
            >
              Choose file
            </Typography>
          </Typography>
        </Box>
      </label>

      <input
        id="file-input"
        ref={inputRef}
        onChange={handleDrop}
        className={classes.importInput}
        type="file"
        accept={
          formats === 'all'
            ? '*/*'
            : formats.map(item => `.${item.toLowerCase()}`).join(", ")
        }
        multiple={multiple}
      />

      <Box className={classes.importFileNoted}>
        {error
          ? <Typography
              component="span"
              className={classes.errorMessage}
            >
              {error}
            </Typography>
          : formats !== 'all'
            ? <Typography
                component="span"
                className={classes.importNoted}
              >
                Supported formats: {formats.map(item => item.toUpperCase()).join(", ")}
              </Typography>
            : null
        }

        {size && (
          <Typography
            component="span"
            className={classes.importNoted}
          >
            {maxFiles
              ? `Maximum ${maxFiles} file${maxFiles > 1 ? 's' : ''} ${size.number}${size.unit} ${maxFiles > 1 ? 'each' : ''}`
              : `Maximum file size: ${size.number}${size.unit}`
            }
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default UploadZone;