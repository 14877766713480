import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#FFFFFF'
  },
  container: {
    width: '100%',
    maxWidth: '1920px',
    height: '100vh',
    background: 'white',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflowY: 'auto'
  },
  header: {
    width: '100%',
    minHeight: '66px',
    padding: '12px 32px 12px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#F1F5F9',
    position: 'sticky',
    gap: '10px',
    top: 0,
    zIndex: 10,
  },
  nameBlock: {
    width: '30%',
    minWidth: '30%',
    maxWidth: '100%',
    // minWidth: '20%',
    // maxWidth: '40%',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  tag: {
    width: 'fit-content',
    padding: '4px 12px',
    border: '1px solid #059669',
    borderRadius: '26px',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    color: '#059669',
  },
}));
