import React, { useState, useEffect, useCallback } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { ReactComponent as MarketingEmailsRobotIcon } from '../../assets/robots/robot_2.svg';
import { ReactComponent as SalesEmailsRobotIcon } from '../../assets/robots/robot_3.svg';
import { ReactComponent as ProductEmailsRobotIcon } from '../../assets/robots/robot_4.svg';
import { ReactComponent as MarketingEmailsIcon } from '../../assets/icons/marketing_emails.svg';
import { ReactComponent as SalesEmailsIcon } from '../../assets/icons/sales_emails.svg';
import { ReactComponent as ProductEmailsIcon } from '../../assets/icons/product_emails.svg';
import { ReactComponent as CampaignCardBackgroundInnerIcon } from '../../assets/icons/campaign_card_background_inner.svg';
import { ReactComponent as CampaignCardBackgroundOutterIcon } from '../../assets/icons/campaign_card_background_outter.svg';
import { ReactComponent as LatterIcon } from '../../assets/icons/latter.svg';
import { getAllCampaigns } from "../../redux/services/tactical-outreach";
import { enqueueSnackbar } from "notistack";
import { getProfile } from "../../redux/services/profile";
import { getOnboardingCompleteness } from "../../redux/services/onboarding";
import ConfirmationModal from "../../components/main/ConfirmationModal";
import { getUrlAndParams, removeUrlParams } from "../../helpers/other";
import { getCurrentSubscription } from "../../redux/services/subscriptions";
import { getCurrentSubscriptionStatus } from "../../helpers/subscription";

import { useStyles } from "./styles";

const TacticalOutreach = (props) => {
  const [tabs, setTabs] = useState<any>({
    'Marketing emails': {
      id: 1,
      mainIcon: <MarketingEmailsRobotIcon />,
      groupIcon: <MarketingEmailsIcon />,
      textColors: {
        main: '#57BE99',
        hover: '#D1FAE5'
      },
      watermarkColors: {
        main: '#57BE99',
        hover: '#FFFFFF'
      },
      campaigns: {}
    },
    'Sales emails': {
      id: 2,
      mainIcon: <SalesEmailsRobotIcon />,
      groupIcon: <SalesEmailsIcon />,
      textColors: {
        main: '#D93855',
        hover: '#FEE2E2'
      },
      watermarkColors: {
        main: '#D93855',
        hover: '#FFFFFF'
      },
      campaigns: {}
    },
    'Product emails': {
      id: 3,
      mainIcon: <ProductEmailsRobotIcon />,
      groupIcon: <ProductEmailsIcon />,
      textColors: {
        main: '#3A84C9',
        hover: '#F8FAFC'
      },
      watermarkColors: {
        main: '#3A84C9',
        hover: '#FFFFFF'
      },
      campaigns: {}
    },
  });
  const [activeTab, setActiveTab] = useState<string | number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [hoverId, setHoverId] = useState<number | null>(null);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState<boolean>(false);
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState<boolean>(false);
  const [showActionBlockedModal, setShowActionBlockedModal] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    const { params } = getUrlAndParams();
    const tab = params.get('tab');

    if (+tab >= 1 && +tab <= 3) {
      setActiveTab(+tab);
  
      setTimeout(() => {
        removeUrlParams(true);
      }, 500);
    } else {
      setActiveTab(tabs[Object.keys(tabs)[0]].id);
    }
  }, []);

  useEffect(() => {
    Promise.allSettled([
      handleIsSubscriptionActive(),
      handleIsOnboardingCompleted(),
    ]).then(() => {
      if (activeTab) {
        handlerGetCampaigns(activeTab);
      }
    })
  }, [activeTab]);

  const handleIsSubscriptionActive = async () => {
    const { data } = await getCurrentSubscription();

    if (getCurrentSubscriptionStatus(data) !== 'EXPIRED') {
      setIsSubscriptionActive(true);
    }
  }

  const handleIsOnboardingCompleted = async () => {
    const { data } = await getOnboardingCompleteness();

    if (data && Object.values(data).every(item => item)) {
      setIsOnboardingCompleted(true);
    }
  }

  const handlerGetCampaigns = async (id) => {
    const targetTabData = (Object.values(tabs).find((item: any) => item.id === id) as any);

    if (!Object.keys(targetTabData.campaigns).length) {
      setLoading(true);
    }

    const { data, error } = await getAllCampaigns(id);

    if (!error && data && Array.isArray(data)) {
      setTimeout(() => {
        setTabs(
          Object.entries(tabs).reduce((acc, [key, value]: [string, any]) => {
            if (value.id === id) {
              acc[key] = {
                ...value,
                campaigns: [...data]
                  .sort((a, b) => a.subCategory.id - b.subCategory.id)
                  .reduce((campaignAcc, item) => {
                    const subCategoryName = item.subCategory.name.toUpperCase();
                    if (!campaignAcc[subCategoryName]) {
                      campaignAcc[subCategoryName] = [];
                    }
                    campaignAcc[subCategoryName].push(item);
                    return campaignAcc;
                  }, {})
              };
            } else {
              acc[key] = value;
            }
            return acc;
          }, {})
        )

        setLoading(false);
      }, !Object.keys(targetTabData.campaigns).length ? 500 : 0);
    } else {
      setLoading(false);
    }
  }

  const getCurrentTabData = () => {
    const tabData: any = Object.values(tabs).find((item: any) => activeTab === item.id) as any;

    return tabData;
  }

  const handleOpenCampaign = async (item) => {
    if (!isOnboardingCompleted || !isSubscriptionActive) {
      setShowActionBlockedModal(true);
    } else {
      const allow = await isAllowCreate();

      if (!allow) {
        enqueueSnackbar(
          `You have reached the limit for creating prompt`,
          { variant: 'error' }
        );
      } else {
        props.history.push(`/tactical-outreach/builder?template_id=${item.id}`)
      }
    }
  }

  const isAllowCreate = async () => {
    return true;
  }

  return (
    <>
      {/* <Backdrop
        open={!activeTab}
        style={{ color: '#fff', zIndex: 9999 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}

      <Box className={classes.container}>
        <Box style={{ width: '87px', height: '87px', margin: '0 auto' }}>
          <Box
            style={{ width: '87px', height: '87px' }}
          >
            {getCurrentTabData()?.mainIcon}
          </Box>
        </Box>
        <Typography className={classes.title}>
          Tactical Outreach
        </Typography>
        <Box className={classes.tabs}>
          {Object.keys(tabs).map((item, index) => (
            <Button
              key={index}
              variant="text"
              className={classes.tab}
              style={{
                background: activeTab === tabs[item].id ? tabs[item].textColors.main : 'transparent',
                color: activeTab === tabs[item].id ? 'white' : tabs[item].textColors.main,
              }}
              onClick={() => setActiveTab(tabs[item].id)}
            >
              {item}
            </Button>
          ))}
        </Box>
        <Box className={classes.groupsWrapper}>
          {(loading || !getCurrentTabData())
            ? <Box className={classes.groupsLoader}>
                <CircularProgress color="inherit" />
              </Box>
            : Object.entries(getCurrentTabData().campaigns).reverse().map(([key, value]: [string, any], index) => (
                <Box className={classes.group} key={index}>
                  <Box className={classes.groupTitle}>
                    {/* {getCurrentTabData().groupIcon} */}
                    <Typography style={{ fontSize: '24px', fontWeight: 600, lineHeight: '29px', textTransform: 'uppercase' }}>
                      {key}
                    </Typography>
                  </Box>
                  <Grid container spacing={3}>
                    {value.map((item, index) => (
                      <Grid item xs={12} md={6} lg={4} key={index}>
                        <Box
                          className={classes.card}
                          style={{
                            borderColor: getCurrentTabData().watermarkColors.main,
                            background: hoverId === item.id ? getCurrentTabData().textColors.main : 'white'
                          }}
                          onMouseOver={() => setHoverId(item.id)}
                          // onMouseEnter={() => setHoverId(item.id)}
                          onMouseLeave={() => setHoverId(null)}
                          onClick={() => handleOpenCampaign(item)}
                        >
                          <Box className={classes.cardHeader}>
                            <LatterIcon style={{ color: getCurrentTabData().watermarkColors.main }} />
                            <Typography style={{ fontSize: '12px', fontWeight: 500, lineHeight: '14px', color: getCurrentTabData().textColors.main, textTransform: 'uppercase' }}>
                              {item.subTitle}
                            </Typography>
                          </Box>
                          <Box className={classes.cardContent}>
                            <Typography style={{ fontSize: '20px', fontWeight: 600, lineHeight: '24px' }}>
                              {item.title}
                            </Typography>
                            <Typography style={{ fontSize: '12px', fontWeight: 500, lineHeight: '14px', color: '#475569' }}>
                              {item.description}
                            </Typography>
                          </Box>
                          <Box className={classes.cardFooter}>
                            <Box className={classes.cardFooterItem}>
                              <Typography style={{ fontSize: '12px', fontWeight: 500, lineHeight: '14px', color: '#64748B' }}>
                                Setup
                              </Typography>
                              <Typography style={{ fontSize: '16px', fontWeight: 600, lineHeight: '24px', textTransform: 'uppercase', color: getCurrentTabData().textColors.main }}>
                                {item.setup}
                              </Typography>
                            </Box>
                            <Box className={classes.cardFooterItem}>
                              <Typography style={{ fontSize: '12px', fontWeight: 500, lineHeight: '14px', color: '#64748B' }}>
                                Parameters
                              </Typography>
                              <Typography style={{ fontSize: '16px', fontWeight: 600, lineHeight: '24px', color: getCurrentTabData().textColors.main }}>
                                {item.parameters}
                              </Typography>
                            </Box>
                          </Box>

                          <Box className={classes.cardBackground}>
                            <Box className={classes.cardBackgroundOutter}>
                              <CampaignCardBackgroundOutterIcon style={{ color: getCurrentTabData().watermarkColors.main }} />
                            </Box>
                            <Box className={classes.cardBackgroundInner}>
                              <CampaignCardBackgroundInnerIcon style={{ color: getCurrentTabData().watermarkColors.main }} />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))
          }
        </Box>
      </Box>

      <ConfirmationModal
        open={showActionBlockedModal}
        title={
          !isSubscriptionActive
            ? 'To proceed, you have to be subscribed'
            : 'To proceed, you have to complete the Onboarding'
        }
        onClose={() => setShowActionBlockedModal(false)}
        reversedButtons
        confirmBtnText={
          !isSubscriptionActive
            ? 'Subscribe later'
            : 'Complete later'
        }
        rejectBtnText={
          !isSubscriptionActive
            ? 'Go to subscriptions'
            : 'Go to onboarding'
        }
        onConfirm={() => setShowActionBlockedModal(false)}
        onReject={() =>
          !isSubscriptionActive
            ? props.history.push('/subscriptions')
            : props.history.push('/onboarding')
        }
      />
    </>
  );
};

export default TacticalOutreach;
