import { colors, makeStyles } from "@material-ui/core";
import UploadDocumentsBackground from '../../assets/images/upload_company_background.png';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    background: '#EBE2CF',
    backgroundImage: `url(${UploadDocumentsBackground})`,
    backgroundSize: "1440px 900px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  container2: {
    width: "100%",
    background: "transparent",
    height: "fit-content",
    "& $form": {
      paddingTop: "0 !important",
      paddingBottom: "0 !important"
    }
  },
  logo: {
    position: "absolute",
    top: 20,
    left: 40
  },
  backBtn: {
    position: "absolute",
    top: 84,
    left: 40,
    borderRadius: "50%",
    backgroundColor: "transparent",
    padding: 12,
    border: "1px solid #E2E8F0",
    cursor: "pointer"
  },

  backBtnIcon: {
    fontSize: 16
  },

  inner: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 1
  },

  form: {
    position: "relative",
    boxSizing: "border-box",
    padding: 40,
    borderRadius: 28,
    maxWidth: 848,
    width: "100%",
    background:
      "linear-gradient(144.88deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },

  formTop: {},

  formHeading: {
    fontWeight: 700,
    fontSize: 30,
    lineHeight: "32px"
  },

  formHeadingIcon: {
    position: "absolute",
    top: 20,
    right: 20
  },

  formDescription: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "21px",
    textAlign: "center",
    marginTop: 12,
    maxWidth: 536
  },

  fileSectionWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: 32
  },

  fileInput: {
    width: "100%"
  },

  dropfileWrapper: {
    borderRadius: 20,
    width: "100%",
    border: "1px solid #CBD5E1",
    background: "#3A84C90D",
    height: 136,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer"
  },

  uploadIcon: {
    fontSize: 24,
    color: "#3A84C9"
  },

  uploadTitle: {
    marginTop: 8,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "22px",
    display: "flex"
  },

  chooseFile: {
    color: "#3A84C9",
    textDecorationLine: "underline",
    fontWeight: 600,
    marginLeft: 4
  },

  input: {
    display: "none"
  },

  fileNoted: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0 6px",
    marginTop: 8
  },

  noted: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "22px",
    color: "#8C8C8C"
  },

  action: {
    marginTop: 32,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: "20px"
  },

  cancelBtn: {
    borderRadius: 8,
    border: "1px solid #CBD5E1",
    fontWeight: 600,
    fontSize: 16,
    lineheight: "19.2px",
    textTransform: "capitalize",
    padding: "10px 16px",
    width: "100%",
    cursor: "pointer",
    backgroundColor: "#FFFFFF"
  },

  submitBtn: {
    borderRadius: 8,
    backgroundColor: "#000000",
    marginLeft: 20,
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: 16,
    lineheight: "19.2px",
    textTransform: "capitalize",
    padding: "10px 16px",
    width: "100%",
    cursor: "pointer"
  },

  fileWrapper: {
    marginTop: 15,
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },

  itemWrapper: {
    display: "flex",
    padding: "8px 0px 8px 20px",
    marginRight: "22px",
    alignItems: "center"
  },

  fileScrollview: {
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: 8
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#CBD5E1"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#64748B",
      borderRadius: 8
    }
  },

  fileIcon: {
    width: 35,
    height: 35,
    color: "#E04E4E",
    marginRight: "15px"
  },

  fileContent: {
    display: "flex",
    alignItems: "center"
  },

  fileInfo: {
    width: 350
  },

  font16: {
    fontSize: "16px",
    lineHeight: "22px"
  },

  font12: {
    fontSize: "12px",
    lineHeight: "22px",
    fontWeight: 400
  },

  fontWeight600: {
    fontWeight: 600
  },

  deleteBtn: {
    marginLeft: "auto",
    '& svg': {
      color: '#64748B'
    }
  },

  dropdown: {
    marginTop: 0,
    width: 200,
    borderRadius: 8
  },

  dropownInput: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "21px",
    color: "#22507B",
    padding: "8px 12px",
    border: "1px solid #CBD5E1",
    borderRadius: 8
  },

  deleteBtnIcon: {
    fontSize: 16
  },

  fileSelectInner: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 12
  },

  errorMessage: {
    color: "#DC2626",
    marginTop: "2px",
    fontSize: "12px",
    lineHeight: 1.2,
    fontWeight: 500
  },
  highlightIcon: {
    fontSize: 28,
    color: "#3A84C9",
    cursor: "pointer",
    zIndex: 3
    // border: '6px solid #FFFFFF',
    // borderRadius: '50%',
    // '& path': {
    //   width: '16px',
    //   Height: '16px',
    // }
  },
}));
